// @flow

import React, { type Node } from "react";

import { UpdateScene } from "../../containers";
import NotFoundPage from "../404";

type Props = {
    id: string,
};

const SceneUpdate = ({ id }: Props): Node => {
    if (!id) {
        return <NotFoundPage />;
    }

    return <UpdateScene sceneId={id} />;
};

export default SceneUpdate;
